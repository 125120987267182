<template>
  <div class="merchantlist">
    <NavBar :list="navList" name="商铺类型"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput v-model="formInline.name" placeholder="输入商铺类型名称">
            <template slot="pre">商铺类型名称:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyButton icon title="搜索" @click="currentChange(1)">
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton title="新增商铺类型" :accessId="16975" @click="dialogVisible = true">
            <template slot="preImage">
              <img src="@/unit/img/zj.png" alt="" />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <div class="public-table">
        <el-table :data="tableData" height="95%" v-loading="loading" :header-cell-style="{
          'text-align': 'center',
          background: 'rgb(245, 245, 245)',
        }" :cell-style="{ 'text-align': 'center' }">
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column label="商铺类型名称" prop="name"></el-table-column>
          <el-table-column label="简称" prop="abbreviate"></el-table-column>
          <el-table-column label="商铺类型拥有商铺数" prop="merchants_stall_total"></el-table-column>
          <el-table-column label="创建时间" prop="create_at"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <pop tips="编辑" :accessId="95210" popRight @myclick="edit(scope.row)">
                <img class="icon" src="../../../assets/img/icon/edit.png" alt />
              </pop>
              <pop tips="删除" :accessId="93723" @myclick="del(scope.row)">
                <img class="icon" src="../../../assets/img/icon/deleted.png" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="apiPage">
        <el-pagination next-text="下一页" prev-text="上一页" layout="total, prev, pager, next" :total="total"
          :page-size="formInline.pageSize" :current-page="formInline.pageNum"
          @current-change="currentChange"></el-pagination>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" title="新增商铺类型" :visible.sync="dialogVisible" width="520px"
      @close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="商铺类型名称" prop="name">
          <el-col :span="14">
            <el-input v-model="ruleForm.name" class="public-input" placeholder="请输入商铺类型名称"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="简称">
          <el-col :span="14">
            <el-input v-model="ruleForm.abbreviate"
              @input="ruleForm.abbreviate = ruleForm.abbreviate.replace(/[\W]/g, '')" class="public-input"
              placeholder="请输入简称"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <MyButton title="取消" right @click="dialogVisible = false"></MyButton>
        <MyButton title="确认" type="primary" @click="confirm"></MyButton>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "merchantlist",
  data () {
    return {
      navList: [
        {
          name: "商铺类型",
        },
      ],
      formInline: {
        pageNum: 1,
        pageSize: 10,
        name: "",
      },
      total: 0,
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        name: '',
        abbreviate: ''
      },
      rules: {
        name: [{ required: true, message: "请输入商铺类型", trigger: "blur" }],
      },
      loading: false,
    };
  },
  created () {
    this.getList();
  },
  methods: {
    confirm () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let url = '';
          if (this.ruleForm.property_type_id) {
            url = '/type/edit';
          } else {
            url = '/type/add';
          }
          this.$request.HttpPost(url, this.ruleForm).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
            this.dialogVisible = false;
          });
        }
      });
    },
    getList () {
      this.loading = true;
      this.$request
        .HttpGet("/type/list", this.formInline)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currentChange (page) {
      this.formInline.pageNum = page;
      this.getList();
    },
    handleClose () {
      this.ruleForm.name = "";
      this.ruleForm.property_type_id = "";
      this.ruleForm.abbreviate = '';
    },
    edit (item) {
      this.ruleForm.property_type_id = item.property_type_id;
      this.ruleForm.name = item.name;
      this.ruleForm.abbreviate = item.abbreviate;
      this.dialogVisible = true;
    },
    del (item) {
      this.$myconfirm("此操作将永久删除该类型, 是否继续?", "提示").then(() => {
        this.$request
          .HttpPost("/type/delete", {
            ids: [item.property_type_id],
          })
          .then((res) => {
            this.$common.notifySuccess(res.msg);
            this.getList();
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
